
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    index: Number,
    text: String,
    success: Boolean,
  },
})
