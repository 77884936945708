<template>
  <button class="c-results-item">
    <div class="c-results-item__wrapper">
      <span class="c-results-item__index"> {{ index }}. </span>
      <span class="c-results-item__text">
        {{ text }}
      </span>
      <span class="c-results-item__result">
        <i :class="['vb-icon', success ? 'vb-icon-check' : 'vb-icon-close']" />
        {{ success ? $t('RESULT_SUCCESS') : $t('RESULT_FAIL') }}
      </span>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    index: Number,
    text: String,
    success: Boolean,
  },
})
</script>

<style lang="scss">
.c-results-item {
  @include btnReset();

  width: 100%;
  text-align: left;
  padding: rem(12px) rem(16px);
  background: $results-item-bg;
  border-radius: rem(6px);
  margin: rem(8px) auto;
  box-shadow: 0 0 rem(16px) rem(9px) $results-content-shadow;
}

.c-results-item__wrapper {
  display: flex;
}

.c-results-item__index {
  font-weight: 700;
}

.c-results-item__text {
  width: calc(100% - rem(120px));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: rem(8px);
}

.c-results-item__result {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: 700;

  .vb-icon {
    margin-right: rem(6px);
    font-size: rem(20px);
  }

  .vb-icon-check {
    color: $results-item-success;
  }

  .vb-icon-close {
    color: $results-item-fail;
  }
}
</style>
