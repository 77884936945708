<template>
  <Topbar
    back-url="/"
    back-icon="close"
    header-const="TOPBAR_RESULTS_HEADING"
  />
  <div
    :class="['v-results', success ? 'v-results--success' : 'v-results--fail']"
  >
    <div class="v-results__wrapper">
      <img class="v-results__img" src="/img/results-image.png" alt="" />
      <TheHeading class="v-results__heading"
        >{{ $t('RESULTS_HEADING') }}
        <span class="v-results__heading-color">{{
          headingBasedOnResult
        }}</span></TheHeading
      >
      <div class="v-results__count-text">
        {{ answersCountText }}
      </div>
      <div class="v-results__description">
        {{ $t('RESULTS_DESCRIPTION') }}
      </div>

      <div class="v-results__items-container">
        <ResultsItem
          v-for="(question, i) in questionsSet"
          :key="question.id"
          :index="i + 1"
          :text="question.text"
          :success="userAnswers[i].answer?.correct"
          @click="onResultItemClick(question, userAnswers[i].answer?.correct)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant } from '@/core'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import useModal from '@/composables/useModal'
import TheHeading from '@/components/TheHeading.vue'
import Topbar from '@/components/Topbar.vue'
import ResultsItem from '@/components/ResultsItem.vue'
import { Question, UserAnswer } from 'types/interface/ComposeQuestion'
import useResults from '@/composables/useResults'
import useQuestions from '@/composables/useQuestions'
import tenantConfig from '@/config/tenants/trafikk'
import useChapters from '@/composables/useChapters'

export default defineComponent({
  components: {
    Topbar,
    TheHeading,
    ResultsItem,
  },
  props: {},

  setup() {
    const { tenant } = useTenant()
    const { fetchAllQuestions, allQuestions } = useQuestions()
    const route = useRoute()
    const resultsTimestamp: number = parseInt(
      `${route.params.resultsTimestamp}`,
    )
    const set: string = route.params.set.toString()
    const { closeModal, setModal } = useModal()
    const { questionsByIds } = useQuestions()
    const { getUserAnswers, numCorrect } = useResults()
    const userAnswers = ref([])
    const questionsSet = computed(() =>
      questionsByIds(
        userAnswers.value.map((answer: UserAnswer) => answer.questionId),
      ),
    )
    const { updateProgressStatus } = useChapters()
    const { t } = useI18n()
    const results: Array<string> = []
    const score = computed<number>(() => numCorrect(userAnswers.value))
    const success = computed<boolean>(
      () => score.value >= tenantConfig.testLogic.passingScore,
    )
    const headingBasedOnResult = computed(
      () =>
        (success.value && `${t('RESULTS_HEADING_SUCCESS')}!`) ||
        `${t('RESULTS_HEADING_FAIL')}.`,
    )
    const answersCountText = computed(() =>
      t('RESULTS_ANSWERS_COUNT', [
        numCorrect(userAnswers.value),
        questionsSet.value.length,
      ]),
    )
    const onResultItemClick = (item: Question, correct: boolean): void => {
      setModal('ModalResults', {
        title: 'Svar',
        content: item,
        type: 'primary',
        success: correct,
        actions: {
          confirm: {
            title: correct
              ? t('RESULTS_MODAL_BTN_TEXT_SUCCESS')
              : t('RESULTS_MODAL_BTN_TEXT_FAIL'),
            on() {
              closeModal()
            },
          },
        },
      })
    }

    useHead({
      title: computed(() => `${t('RESULTS_HEADING')} - ${tenant.value.name}`),
    })

    onMounted(async () => {
      if (!allQuestions.value.length) {
        await fetchAllQuestions(set)
      }
      const ua = await getUserAnswers(resultsTimestamp)

      userAnswers.value = ua
      await updateProgressStatus()
    })

    return {
      answersCountText,
      results,
      userAnswers,
      headingBasedOnResult,
      success,
      questionsSet,
      onResultItemClick,
    }
  },
})
</script>

<style lang="scss">
.v-results {
  padding-top: rem($topbar-height);
  box-shadow: 0 -0.25rem 0.5rem $app-container-outline;
  &__wrapper {
    border-radius: rem(16px);
    background: $results-content-bg;
    margin: rem(24px) rem(16px);
    box-shadow: 0 0 rem(16px) rem(9px) $results-content-shadow;
  }

  &__img {
    display: block;
    margin-top: rem(16px);
    width: 100%;
    height: auto;
  }

  &__heading {
    padding: 0 rem(16px);
  }

  &__heading-color {
    &--success & {
      color: $results-heading-color-success;
    }
    &--fail & {
      color: $results-heading-color-fail;
    }
  }

  &__count-text {
    font-size: rem(20px);
    font-weight: 700;
    padding: 0 rem(16px);
  }

  &__description {
    margin-top: rem(24px);
    margin-bottom: rem(20px);
    line-height: rem(24px);
    padding: 0 rem(16px);
  }
}
</style>
